




import Vue from 'vue';
import FullSizeCard from './FullSizeCard.vue';

export default Vue.extend({
  name: 'ConnectWalletCard',
  components: {
    FullSizeCard,
  },
  data: () => ({}),
});
